@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;1,100;1,200&family=Urbanist:wght@100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


 /* poppins */

@font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Light.ttf);                
    font-weight: 300;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Regular.ttf);                
    font-weight: 400;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Medium.ttf);                
    font-weight: 500;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-SemiBold.ttf);                
    font-weight: 600;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Bold.ttf);                
    font-weight: 700;
  }
  
  .poppins {
    font-family: poppins;
  }

    /* inter */
  
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-Light.ttf);                
      font-weight: 300;
    }
    
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-Regular.ttf);                
      font-weight: 400;
    }
    
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-Medium.ttf);                
      font-weight: 500;
    }
    
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-SemiBold.ttf);                
      font-weight: 600;
    }
    
    @font-face {
      font-family: inter;
      src: url(../public/fonts/inter/Inter-Bold.ttf);                
      font-weight: 700;
    }
    
    .inter {
      font-family: inter;
    }
    
  
body {
  background-color: #080909;
  top: 0 !important;
}

.token-buy-border {
  background: conic-gradient(from -90deg at 50% 50%, #1DF49A 0deg, #F4911D 91.88deg, #C91DF4 178.77deg, #6F1ADB 270deg, #1DF49A 360deg);
}

.static-block-bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
  linear-gradient(180deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.001) 100%);
}

.static-block-border {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.title-text-color {
  background: radial-gradient(493.16% 266.63% at 50% -148.57%, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tokenomics-menu-border {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%);
}

.tokenomics-menu-bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%),
  linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03));
}

.giveaway_block_bg {
  background: linear-gradient(90deg, #161717 0%, #0A0A0A 63.53%, #0F0F0F 100%);
}

button:disabled {
  opacity: 0.4;
}

.goog-te-gadget-simple span:first-of-type {
  display: flex !important;
  color: #ffffff;
}

.goog-te-gadget-simple span:not(:first-of-type) {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  position: static;
  margin-top: 4rem;
  display: none !important;
}

.goog-te-menu-frame.goog-te-menu2 {
  background-color: red;

}

.hide_select__translate.skiptranslate span {
  @media (max-width: 1121px) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    color: transparent;
}
}

.goog-te-menu2 {
  overflow: auto;
}

.goog-te-gadget-simple img {
  opacity: 0;
  margin-right: 6px;
}

.goog-te-menu-value {
  display: inline-flex;
  align-items: center;
}

.goog-te-menu-value span:nth-of-type(2) {
  display: none;
}

.goog-te-menu-value span {
  display: inline-flex;
  color: #ffffff;
  align-items: center;
}

.goog-te-menu-value span:last-child {
  display: none;
}

#goog-gt-tt {
  display: none !important;
}

.skiptranslate iframe {
  display: none;
}

.goog-te-menu-value img {
  display: none;
}

.goog-te-gadget-simple {
  display: flex!important;
  background-color: transparent!important;
  border: transparent!important;
  color: white !important;
  overflow: hidden!important;
  align-items: center!important;
}

.clixBanner_bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/public/images/clixBanner/bg.png');
}

@media (max-width: 767px) {
  .clixBanner_bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/public/images/clixBanner/mobBg.png');
  }
}

.border-roadmap {
  background: linear-gradient(180deg, #32CC66 0%, #252525 100%);
}

.grey-border-roadmap {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%);
}

button:hover {
  opacity: .75;
} 

a:hover {
  opacity: .75;
} 

.mexc-banner-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)),
  linear-gradient(72.44deg, #0457E5 0%, #3C8AFF 100%);
}

.mexc-button-shadow {
  box-shadow: 0px 10px 15px 0px rgba(13, 95, 233, 0.50);
}

.statics_main_page {
  background: linear-gradient(106.1deg, #FFFFFF -1.51%, rgba(255, 255, 255, 0) 100.13%);
}

.smartContract_bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #070707 81%);
}

.swap_bg {
  background: linear-gradient(91deg, rgba(112, 138, 255, 0.30) -0.48%, rgba(81, 96, 191, 0.15) 101.05%);
}

.youPay_bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
} 

.advantages_border {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.swap_arrow_button {
  background: linear-gradient(147deg, #1C4D97 4.54%, #5A67CE 37.3%, #1F1D82 88.21%);
}

.supply_bg {
  background: linear-gradient(180deg, #FDD957 0%, #FBFBF9 100%);
}

.maxSupply_bg {
  background: linear-gradient(0deg, #6640FF 0%, #6640FF 100%), rgba(255, 255, 255, 0.02);
}

.burned_bg {
  background: linear-gradient(180deg, #EA481E 0%, #F37E19 100%), rgba(255, 255, 255, 0.02);
}

.faq_title {
  background: linear-gradient(146.74deg, #1C4D97 4.54%, #5A67CE 37.3%, #1F1D82 88.21%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.main_frgx_title {
  background: radial-gradient(105.27% 817.96% at -0.21% -26.19%, #EAFAFF 7%, #91E8FE 28%, #6AB7FE 60.17%, #3177FF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.main_frgx_desc {
  background: linear-gradient(93deg, #FFF 51.11%, rgba(255, 255, 255, 0.50) 90.06%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.transfer_base_button {
  background: radial-gradient(100% 442.31% at 100% 50%, rgba(148, 210, 255, 0.2) 0%, rgba(0, 0, 0, 0.01) 100%);
  box-shadow: 0px 0px 12px 0px #94D2FF66 inset;
}

.smart_contract_bg {
  background: radial-gradient(100% 235.87% at 99.9% 50%, rgba(79, 148, 255, 0.2) 0%, rgba(0, 0, 0, 0.01) 100%);
  box-shadow: 0px 0px 12px 0px #4F94FF66 inset;
}

.smart_contract_text {
  background: linear-gradient(136deg, #FFF 38.25%, rgba(255, 255, 255, 0.40) 130.5%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}